import React from "react"

const Layout = ({ className, location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className={`w-full ${className}`}>
      <main>{children}</main>
      <footer></footer>
    </div>
  )
}

export default Layout
